import React, { useEffect, useState } from "react";
import personal from "../assets/personal.png";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";

const Profilepage = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    {
      original: personal
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
        <div>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://github.com/Carlito-Pedida/html-css-ajax-resume-landing"
            target="_blank"
            rel="noreferrer"
          >
            <p>project_files_HTML_CSS_AJAX</p>
          </a>
        </div>
        <div className="featured col-md-12 col-lg-6 my-3">
          <div className="changing px-2 mb-3">
            <h4>
              Personal Website:{" "}
              <span style={{ color: "orange" }}> Front-End </span>
              Design
            </h4>
            <p>
              HTML, CSS, AJAX, XML
              <br />
              Role: Front End Designer | Developer
            </p>
          </div>

          <img
            style={{ cursor: "pointer" }}
            onClick={setShow}
            src={personal}
            alt="Portfolio"
          />
        </div>
      </div>
      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>Profile Landing: Gallery</Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Profilepage;
