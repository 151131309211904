import React, { useEffect, useState } from "react";
import "../Styles/Skills.css";
import { Table } from "react-bootstrap";

const Skills = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".skills-case");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="skills-case">
      <div className="skills-box">
        <div>
          <p className="skills-title text-center">
            <span style={{ color: "white" }}>Technology </span>
            <span style={{ color: "orange" }}>Sets</span>
            <span style={{ color: "white" }}></span>
          </p>
        </div>

        <div className="skills-column justify-content-center my-3 ">
          <div
            className={`skills-list  col-md-12 col-lg-3  my-2 p-3 ${
              fadeIn ? "fade-in-left" : ""
            }`}
          >
            <div>
              <h5>
                Front End <span style={{ color: "orange" }}>Design </span>&
                Development
              </h5>
            </div>
            <Table
              className="custom-table"
              style={{
                opacity: "80%"
              }}
              striped
              bordered
              hover
              variant="dark"
            >
              <tbody>
                <tr>
                  <td>REACT | JavaScript</td>
                </tr>
                <tr>
                  <td>Redux | Tanstack - React Query</td>
                </tr>
                <tr>
                  <td>Zustand</td>
                </tr>
                <tr>
                  <td>HTML5</td>
                </tr>
                <tr>
                  <td>CSS3</td>
                </tr>
                <tr>
                  <td>Bootstrap | Chakra</td>
                </tr>
                <tr>
                  <td>Radix UI | Material UI</td>
                </tr>
                <tr>
                  <td>AJAX - JQuery</td>
                </tr>
                <tr>
                  <td>Axios</td>
                </tr>
                <tr>
                  <td>REACT Native - Ionic - Capacitor</td>
                </tr>
                <tr>
                  <td>UX / UI Design - Figma</td>
                </tr>
                <tr>
                  <td>Ruby</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className={`skills-list col-md-12 col-lg-3 my-2  p-3  ${
              fadeIn ? "fade-in" : ""
            }`}
          >
            <div>
              <h5>
                Back<span style={{ color: "orange" }}> End </span>
                Development
              </h5>
            </div>
            <Table
              className="custom-table"
              style={{
                opacity: "80%"
              }}
              striped
              bordered
              hover
              variant="dark"
            >
              <tbody>
                <tr>
                  <td>REACT | TypeScript</td>
                </tr>
                <tr>
                  <td>MVC</td>
                </tr>
                <tr>
                  <td>Express</td>
                </tr>
                <tr>
                  <td>Node</td>
                </tr>
                <tr>
                  <td>Sequelize</td>
                </tr>
                <tr>
                  <td>Mongoose</td>
                </tr>
                <tr>
                  <td>SQL/NoSQL</td>
                </tr>
                <tr>
                  <td>Jason Web Token</td>
                </tr>
                <tr>
                  <td>RSS</td>
                </tr>
                <tr>
                  <td>REST API</td>
                </tr>
                <tr>
                  <td>Django - Python</td>
                </tr>
                <tr>
                  <td>Rails 7 - PostgreSQL</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div
            className={`skills-list  col-md-12 col-lg-3  my-2  p-3  ${
              fadeIn ? "fade-in-right" : ""
            }`}
          >
            <div>
              <h5>
                Deployment & <span style={{ color: "orange" }}>Cloud</span>{" "}
                Services
              </h5>
            </div>
            <Table
              className="custom-table"
              style={{
                opacity: "80%"
              }}
              striped
              bordered
              hover
              variant="dark"
            >
              <tbody>
                <tr>
                  <td>AMAZON WEB SERVICES</td>
                </tr>
                <tr>
                  <td>Amplify</td>
                </tr>
                <tr>
                  <td>S3 Buckets</td>
                </tr>
                <tr>
                  <td>EC2</td>
                </tr>
                <tr>
                  <td>Route53</td>
                </tr>
                <tr>
                  <td>RDS</td>
                </tr>
                <tr>
                  <th>OTHER SERVICES</th>
                </tr>
                <tr>
                  <td>Google Firebase</td>
                </tr>
                <tr>
                  <td>Google Maps API</td>
                </tr>
                <tr>
                  <td>Mapbox API</td>
                </tr>
                <tr>
                  <td>Vercel / Netlify</td>
                </tr>
                <tr>
                  <td>Render / Heroku</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
