import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import gamehub1 from "../assets/gamehub/gamehub1.png";
import gamehub2 from "../assets/gamehub/gamehub2.png";
import gamehub3 from "../assets/gamehub/gamehub3.png";
import gamehub4 from "../assets/gamehub/gamehub4.png";
import gamehub5 from "../assets/gamehub/gamehub5.png";
import gamehub6 from "../assets/gamehub/gamehub6.png";
import { Button } from "react-bootstrap";

const GameHub = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    {
      original: gamehub1,
      thumbnail: gamehub1
    },
    {
      original: gamehub2,
      thumbnail: gamehub2
    },
    {
      original: gamehub3,
      thumbnail: gamehub3
    },
    {
      original: gamehub4,
      thumbnail: gamehub4
    },
    {
      original: gamehub5,
      thumbnail: gamehub5
    },
    {
      original: gamehub6,
      thumbnail: gamehub6
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
        <div className="mb-3">
          <Button
            onClick={() => {
              window.open("https://technode-game-hub.vercel.app/", "_blank");
            }}
            variant="warning"
          >
            VIEW LIVE SITE
          </Button>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="row">
            <div className="product">
              <p></p>
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={gamehub6}
                alt="Portfolio"
              />
            </div>
          </div>
          <div className="secondary row">
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product my-2">
                <p></p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={gamehub2}
                  alt="Portfolio"
                />
              </div>
              <div className="product my-2">
                <p></p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={gamehub3}
                  alt="Portfolio"
                />
              </div>
            </div>
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product col my-2">
                <p></p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={gamehub4}
                  alt="Portfolio"
                />
              </div>
              <div className="product col my-2">
                <p></p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={gamehub5}
                  alt="Portfolio"
                />
              </div>
            </div>
          </div>

          <div className="row"></div>
        </div>
        <div className="featured col-md-12 col-lg-6 my-3">
          <div className="changing px-2 mb-3">
            <h4>
              TechNode Game Hub:{" "}
              <span style={{ color: "orange" }}>
                {" "}
                Interactive Game Central{" "}
              </span>{" "}
            </h4>
            <p>
              React Typescript, Vite, Context, API, React Query, Zustand, VERCEL
              Deployment
              <br />
              Role: UI Designer and Developer
            </p>
          </div>

          <p></p>
          <img
            style={{ cursor: "pointer" }}
            onClick={setShow}
            src={gamehub1}
            alt="Portfolio"
          />
        </div>
      </div>
      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Button
            onClick={() => {
              window.open("https://technode-game-hub.vercel.app/", "_blank");
              setShow(false);
            }}
          >
            VIEW LIVE SITE
          </Button>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GameHub;
