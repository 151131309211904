import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import database from "../assets/peoplesoft/database.png";
import empedit from "../assets/peoplesoft/empedit.png";
import employee from "../assets/peoplesoft/employee.png";
import landing from "../assets/peoplesoft/landing.png";
import manager from "../assets/peoplesoft/manager.png";
import support from "../assets/peoplesoft/support.png";
import { Button } from "react-bootstrap";

const PeopleSoft = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    {
      original: landing,
      thumbnail: landing
    },
    {
      original: database,
      thumbnail: database
    },
    {
      original: manager,
      thumbnail: manager
    },
    {
      original: empedit,
      thumbnail: empedit
    },
    {
      original: employee,
      thumbnail: employee
    },
    {
      original: support,
      thumbnail: support
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
        <div className="mb-3">
          <Button
            onClick={() => {
              window.open("https://peoplesoft-ui.vercel.app/", "_blank");
            }}
            variant="warning"
          >
            VIEW LIVE SITE
          </Button>
        </div>
        <div className="featured col-md-12 col-lg-6 my-3">
          <div className="changing px-2 mb-3">
            <h4>
              People Software:{" "}
              <span style={{ color: "orange" }}> People Database </span> for
              businesses
            </h4>
            <p>
              MERN Stack / VERCEL MongoDB Atlas Integration
              <br />
              Role: Full Stack Designer and Developer
            </p>
          </div>
          <p>Landing Page</p>
          <img
            style={{ cursor: "pointer" }}
            onClick={setShow}
            src={landing}
            alt="Portfolio"
          />
        </div>

        <div className="col-md-12 col-lg-4">
          <div className="row">
            <div className="product">
              <p>People Data</p>
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={database}
                alt="Portfolio"
              />
            </div>
          </div>
          <div className="secondary row">
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product my-2">
                <p>Manager Profile</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={manager}
                  alt="Portfolio"
                />
              </div>
              <div className="product my-2">
                <p>Employee Details</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={employee}
                  alt="Portfolio"
                />
              </div>
            </div>
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product col my-2">
                <p>Data Update</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={empedit}
                  alt="Portfolio"
                />
              </div>
              <div className="product col my-2">
                <p>Support Page</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={support}
                  alt="Portfolio"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Button
            onClick={() => {
              window.open("https://peoplesoft-ui.vercel.app/", "_blank");
              setShow(false);
            }}
          >
            VIEW LIVE SITE
          </Button>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PeopleSoft;
