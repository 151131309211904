import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import tnpMessaging from "../assets/technodepro/tnpMessaging.png";
import tnpHome from "../assets/technodepro/tnpHome.png";
import tnpUserPage from "../assets/technodepro/tnpUserPage.png";
import tnpUsersPage from "../assets/technodepro/tnpUsersPage.png";
import tnplogged from "../assets/technodepro/tnplogged.png";
import tnpArticles from "../assets/technodepro/tnpArticles.png";
import { Button } from "react-bootstrap";

const Technodepro = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    {
      original: tnpHome,
      thumbnail: tnpHome
    },
    {
      original: tnplogged,
      thumbnail: tnplogged
    },
    {
      original: tnpUserPage,
      thumbnail: tnpUserPage
    },
    {
      original: tnpMessaging,
      thumbnail: tnpMessaging
    },
    {
      original: tnpArticles,
      thumbnail: tnpArticles
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
        <div className="mb-3">
          <Button
            onClick={() => {
              window.open("https://technodepro.onrender.com/", "_blank");
            }}
            variant="warning"
          >
            VIEW LIVE SITE
          </Button>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="row">
            <div className="product">
              <p>User Profile</p>
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={tnpUserPage}
                alt="Portfolio"
              />
            </div>
          </div>
          <div className="secondary row">
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product my-2">
                <p>Logged Session</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={tnplogged}
                  alt="Portfolio"
                />
              </div>
              <div className="product my-2">
                <p>Article View</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={tnpMessaging}
                  alt="Portfolio"
                />
              </div>
            </div>
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product col my-2">
                <p>Contributors Page</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={tnpUsersPage}
                  alt="Portfolio"
                />
              </div>
              <div className="product col my-2">
                <p>Articles Page</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={tnpArticles}
                  alt="Portfolio"
                />
              </div>
            </div>
          </div>

          <div className="row"></div>
        </div>
        <div className="featured col-md-12 col-lg-6 my-3">
          <div className="changing px-2 mb-3">
            <h4>
              TechNode Pro: <span style={{ color: "orange" }}> Full Stack</span>{" "}
              Web Application
            </h4>
            <p>
              Ruby, Rails 7.2, PostgreSQL, Render
              <br />
              Role: Full Stack Designer and Developer
            </p>
          </div>

          <p>Home Page</p>
          <img
            style={{ cursor: "pointer" }}
            onClick={setShow}
            src={tnpHome}
            alt="Portfolio"
          />
        </div>
      </div>
      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Button
            onClick={() => {
              window.open("https://technodepro.onrender.com/", "_blank");
            }}
            variant="warning"
          >
            VIEW LIVE SITE
          </Button>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Technodepro;
