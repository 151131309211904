import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import prototyping from "../assets/ux_ui/prototyping.png";
import ui_design from "../assets/ux_ui/ui_design.png";
import ux_ui_design from "../assets/ux_ui/ux_ui_design.png";
import wireframing from "../assets/ux_ui/wireframing.png";
import { Button } from "react-bootstrap";

const UxUi = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    {
      original: ux_ui_design,
      thumbnail: ux_ui_design
    },
    {
      original: wireframing,
      thumbnail: wireframing
    },
    {
      original: ui_design,
      thumbnail: ui_design
    },
    {
      original: prototyping,
      thumbnail: prototyping
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
        <div className="mb-3">
          <Button
            onClick={() => {
              window.open(
                "https://www.figma.com/proto/FIgzUfzvnOifgnQsgkAouP/CPedida-Ecom-v1?node-id=152-523&node-type=FRAME&t=BOlC0qCnQ0GHTai7-1&scaling=scale-down&content-scaling=fixed&page-id=39%3A71&starting-point-node-id=39%3A78",
                "_blank"
              );
            }}
            variant="warning"
          >
            VIEW PROTOTYPE
          </Button>
        </div>
        <div className="featured col-md-12 col-lg-6 my-3">
          <div className="changing px-2 mb-3">
            <h4>
              UX / UI: <span style={{ color: "orange" }}> Design </span>
            </h4>
            <p>
              Figma / Photoshop / Illustrator / Animation
              <br />
              Role: Designer
            </p>
          </div>
          <p>UX / UI Design</p>
          <img
            style={{ cursor: "pointer" }}
            onClick={setShow}
            src={ux_ui_design}
            alt="Portfolio"
          />
        </div>

        <div className="col-md-12 col-lg-4">
          <div className="row">
            <div className="product">
              <p>Wireframing</p>
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={wireframing}
                alt="Portfolio"
              />
            </div>
          </div>
          <div className="row">
            <div className="product">
              <p>UI Design</p>
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={ui_design}
                alt="Portfolio"
              />
            </div>
          </div>
          <div className="row">
            <div className="product">
              <p>Prototyping</p>
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={prototyping}
                alt="Portfolio"
              />
            </div>
          </div>
          <div className="secondary row"></div>
        </div>
      </div>
      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Button
            onClick={() => {
              window.open(
                "https://www.figma.com/proto/FIgzUfzvnOifgnQsgkAouP/CPedida-Ecom-v1?node-id=152-523&node-type=FRAME&t=BOlC0qCnQ0GHTai7-1&scaling=scale-down&content-scaling=fixed&page-id=39%3A71&starting-point-node-id=39%3A78",
                "_blank"
              );
              setShow(false);
            }}
          >
            VIEW PROTOTYPE
          </Button>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UxUi;
