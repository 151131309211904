import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TabContainer from "react-bootstrap/TabContainer";
import Worshipgrid from "../Projects/Worshipgrid";
import Technodepro from "../Projects/Technodepro";
import Mobileapp from "../Projects/Mobileapp";
import Carportal from "../Projects/Carportal";
import "../Styles/Portfolio.css";
import PeopleSoft from "../Projects/PeopleSoft";
import GameHub from "../Projects/Gamehub";
import IssueTracker from "../Projects/IssueTracker";
import Profilepage from "../Projects/Profilepage";
import UxUi from "../Projects/UxUi";

const Projects = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".portfolio-case");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      // If the section is in view
      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    // Add event listener for scrolling
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="portfolio-case">
      <p
        className={`completed text-center mx-2 mb-0 ${
          fadeIn ? "fade-in-down" : ""
        }`}
      >
        <span style={{ color: "white" }}>Completed </span>
        <span style={{ color: "orange" }}>Projects</span>
      </p>

      <div className={`showcase-main`}>
        <TabContainer>
          <Tabs
            defaultActiveKey="worshipgrid"
            id="justify-tab-example"
            className={`mb-3 px-3 ${fadeIn ? "fade-in-left" : ""}`}
            justify
          >
            <Tab className="" eventKey="worshipgrid" title="REACT / NODE.JS">
              <Worshipgrid />
            </Tab>

            <Tab eventKey="issue_tracker" title="NEXT.JS / SQL">
              <IssueTracker />
            </Tab>

            <Tab eventKey="gamehub" title="REACT+VITE+API">
              <GameHub />
            </Tab>

            <Tab eventKey="peoplesoft" title="MERN Stack">
              <PeopleSoft />
            </Tab>

            <Tab eventKey="technodepro" title="Ruby-On-Rails">
              <Technodepro />
            </Tab>

            <Tab eventKey="ux_ui" title="UX / UI Design Figma">
              <UxUi />
            </Tab>

            <Tab eventKey="ionic-social" title="REACT NATIVE">
              <Mobileapp />
            </Tab>
            <Tab eventKey="car-portal" title="REACT">
              <Carportal />
            </Tab>

            <Tab eventKey="landing" title="HTML/CSS/JQuery">
              <Profilepage />
            </Tab>
          </Tabs>
        </TabContainer>
      </div>
    </div>
  );
};

export default Projects;
