import React, { useEffect, useState } from "react";
import "../Styles/About.css";

const About = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".about-container");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="about-container">
        <div className="about-info">
          <p className="about-title text-center mx-2 my-5">
            <span style={{ color: "white" }}>A little bit of </span>
            <span style={{ color: "orange" }}>ME History</span>
          </p>

          <div className="about-content">
            <div className="row">
              <div className="col">
                <div className="container site-desc-container">
                  <div
                    style={{
                      color: "white",
                      fontSize: "20px",
                      textAlign: "left"
                    }}
                  >
                    <h5 className={`about-text ${fadeIn ? "fade-in" : ""}`}>
                      <span style={{ color: "orange" }}>
                        My tech journey started unexpectedly after I landed a
                        video editing job with a parachurch organization in
                        Southeast Asia in 2005. Leveraging only HTML and CSS
                        back then, I was able to place the organization on the
                        Internet. It was a challenging fun process.
                      </span>
                      <br />
                      <br />
                      Diligence, resourcefulness, and the ability to learn new
                      things fast were the skills I acquired through the
                      experience. There were limited resources back then so
                      self-teaching was a real challenge.
                      <br />
                      <br />
                      <span style={{ color: "orange" }}>
                        After a couple of years, we got an amazing opportunity
                        to move to the US as a family. Life went on. In my
                        pursuit to make a difference and provide for my family,
                        I took on a lot of different jobs over the years. I went
                        from a dishwasher to maintenance personnel, to worship
                        director, radio producer, and Technical Media Director,
                        life was great! Then COVID-19 happened. Everything
                        stopped, everything shifted. It was a clear change of
                        season for the whole world.
                      </span>
                      <br />
                      <br />
                      January 2023 I returned to my tech roots and went through
                      a Full Stack Web Development program at Bethel School of
                      Technology. Seeing how the technology space has evolved
                      over the years, I thought the whole learning experience
                      would intimidate me. But the more I got deeper into the
                      program, I found myself even more excited and curious to
                      learn. It was invigorating to rediscover my love for
                      coding!
                      <br />
                      <br />
                      <strong style={{ color: "orange" }}>
                        I have decided to make the most of the time that I have
                        left, however long that is. To be a part of something
                        big, something life-changing. Something that will leave
                        a lasting impact on today's generation and the next. I
                        believe the technology space is great place to achieve
                        that.
                      </strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
