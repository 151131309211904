import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import dashboard from "../assets/issue_tracker/dashboard.png";
import issue_list from "../assets/issue_tracker/issue_list.png";
import issue_detail from "../assets/issue_tracker/issue_detail.png";
import create_new from "../assets/issue_tracker/create_new.png";
import edit_details from "../assets/issue_tracker/edit_details.png";
import delete_warning from "../assets/issue_tracker/delete_warning.png";
import responsive_1 from "../assets/issue_tracker/responsive_1.png";
import responsive_2 from "../assets/issue_tracker/responsive_2.png";
import responsive_3 from "../assets/issue_tracker/responsive_3.png";
import responsive_4 from "../assets/issue_tracker/responsive_4.png";
import responsive_5 from "../assets/issue_tracker/responsive_5.png";
import responsive_6 from "../assets/issue_tracker/responsive_6.png";
import ImageGallery from "react-image-gallery";
import { Button } from "react-bootstrap";

const IssueTracker = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    {
      original: dashboard,
      thumbnail: dashboard
    },
    {
      original: issue_list,
      thumbnail: issue_list
    },
    {
      original: create_new,
      thumbnail: create_new
    },
    {
      original: edit_details,
      thumbnail: edit_details
    },
    {
      original: issue_detail,
      thumbnail: issue_detail
    },
    {
      original: delete_warning,
      thumbnail: delete_warning
    },
    {
      original: responsive_1,
      thumbnail: responsive_1
    },
    {
      original: responsive_2,
      thumbnail: responsive_2
    },
    {
      original: responsive_3,
      thumbnail: responsive_3
    },
    {
      original: responsive_4,
      thumbnail: responsive_4
    },
    {
      original: responsive_5,
      thumbnail: responsive_5
    },
    {
      original: responsive_6,
      thumbnail: responsive_6
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div>
        <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
          <div className="mb-3">
            <Button
              onClick={() => {
                window.open(
                  "https://issue-tracker-application.vercel.app",
                  "_blank"
                );
              }}
              variant="warning"
            >
              VIEW LIVE SITE
            </Button>
          </div>

          <div className="featured col-md-12 col-lg-6">
            <div className="changing px-2 mb-3">
              <h4>
                Full Stack Project{" "}
                <span style={{ color: "orange" }}>Bug Tracker App</span>
              </h4>
              <p>
                Next.JS, Typescript, Prisma, AWS-RDS, API, OAuth, Vercel
                <br />
                Role: Full Stack Designer and Developer
              </p>
            </div>
            <p>Dashboard</p>
            <img
              style={{ cursor: "pointer" }}
              onClick={setShow}
              src={dashboard}
              alt="Portfolio"
            />
          </div>

          <div className="col-md-12 col-lg-4">
            <div className="row">
              <div className="product">
                <p>Dashboard</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={dashboard}
                  alt="Portfolio"
                />
              </div>
            </div>
            <div className="secondary row">
              <div className="secondary col-md-12 col-lg-6 lg-row">
                <div className="product my-2">
                  <p>Issues List</p>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={setShow}
                    src={issue_list}
                    alt="Portfolio"
                  />
                </div>
                <div className="product my-2">
                  <p>Issue Detail</p>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={setShow}
                    src={issue_detail}
                    alt="Portfolio"
                  />
                </div>
              </div>
              <div className="secondary col-md-12 col-lg-6 lg-row">
                <div className="product col my-2">
                  <p>Create New Page</p>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={setShow}
                    src={create_new}
                    alt="Portfolio"
                  />
                </div>
                <div className="product col my-2">
                  <p>Delete / Warning</p>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={setShow}
                    src={delete_warning}
                    alt="Portfolio"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Button
            onClick={() => {
              window.open(
                "https://issue-tracker-application.vercel.app",
                "_blank"
              );
              setShow(false);
            }}
          >
            VIEW LIVE SITE
          </Button>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IssueTracker;
