import { Button, Skeleton, SkeletonText } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Container, Spinner, Stack } from "react-bootstrap";
import coder_left2 from "../assets/coder_left2.jpg";
import "../Styles/Welcome.css";

const Welcome = (props) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      const section = document.querySelector(".welcome-case");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const {
    greeting1,
    greeting2,
    profileName,
    spiel,
    contactButton1,
    contactButton2
  } = props;

  const handleContact1 = (event) => {
    event.preventDefault();
    window.location.href = "#contact";
  };

  const handleContact2 = (event) => {
    event.preventDefault();
    window.location.href = "#portfolio";
  };

  return (
    <>
      <div className={`welcome-case`}>
        <Container fluid>
          <div className="profile-box md-col-12 row justify-content-center">
            <div className="col-md-12 col-lg-5 d-flex justify-content-center">
              {imageLoading && (
                <Spinner
                  animation="border"
                  role="status"
                  className="my-5 spinner"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
              <img
                className={`profileImage my-5 ${imageLoading ? "d-none" : ""}`}
                src={coder_left2}
                alt="author"
                onLoad={handleImageLoad}
              />
            </div>

            <div className="col-md-12 col-lg-6">
              <>
                <div
                  className={`d-flex justify-content-start welcome mb-2 ${
                    fadeIn ? "fade-in" : ""
                  }`}
                >
                  {imageLoading ? (
                    <SkeletonText
                      mb="5"
                      noOfLines={3}
                      spacing="3"
                      skeletonHeight="4"
                    >
                      <p>{spiel}</p>
                    </SkeletonText>
                  ) : (
                    <p className="text-md-start spiel">
                      <a className="spielLink" href="#about">
                        <strong>{spiel}</strong>
                      </a>
                    </p>
                  )}
                </div>
                <div>
                  {imageLoading ? (
                    <SkeletonText
                      mt="4"
                      noOfLines={1}
                      spacing="3"
                      skeletonHeight="8"
                      width="30%"
                    >
                      <h4>{greeting1}</h4>
                    </SkeletonText>
                  ) : (
                    <h4
                      className={`d-flex justify-content-start welcome ${
                        fadeIn ? "fade-in" : ""
                      }`}
                      style={{ color: "orange" }}
                    >
                      {greeting1}
                    </h4>
                  )}
                </div>
                <div>
                  {imageLoading ? (
                    <Skeleton
                      my="5"
                      noOfLines={1}
                      spacing="3"
                      skeletonHeight="15"
                      width="60%"
                    >
                      <h1>{profileName}</h1>
                    </Skeleton>
                  ) : (
                    <h1
                      className={`d-flex justify-content-start myName ${
                        fadeIn ? "fade-in" : ""
                      }`}
                      style={{ color: "white" }}
                    >
                      {profileName}
                    </h1>
                  )}
                </div>
                {imageLoading ? (
                  <SkeletonText
                    mt="3"
                    noOfLines={1}
                    spacing="3"
                    skeletonHeight="7"
                    width="35%"
                  ></SkeletonText>
                ) : (
                  <h4
                    className={`d-flex justify-content-start welcome ${
                      fadeIn ? "fade-in" : ""
                    }`}
                    style={{ color: "orange" }}
                  >
                    {greeting2}
                  </h4>
                )}

                {imageLoading ? (
                  <>
                    <Skeleton
                      style={{ borderRadius: "8px" }}
                      mt="10"
                      noOfLines={1}
                      spacing="3"
                      skeletonHeight="5"
                      width="35%"
                    >
                      <Button> {contactButton1}</Button>
                    </Skeleton>

                    <Skeleton
                      style={{ borderRadius: "8px" }}
                      mt="8"
                      noOfLines={1}
                      spacing="3"
                      skeletonHeight="10"
                      width="35%"
                    >
                      <Button>{contactButton2}</Button>
                    </Skeleton>
                  </>
                ) : (
                  <div
                    className={`d-flex justify-content-md-start justify-content-center my-5 ${
                      fadeIn ? "fade-in" : ""
                    }`}
                  >
                    <Stack>
                      <button
                        className="col-md-4 button"
                        type="submit"
                        onClick={handleContact2}
                      >
                        {contactButton1}
                      </button>
                      <h4
                        className="divider col-md-4 my-2"
                        style={{ color: "white" }}
                      >
                        or
                      </h4>
                      <button
                        className="col-md-4 button"
                        type="submit"
                        onClick={handleContact1}
                      >
                        {contactButton2}
                      </button>
                    </Stack>
                  </div>
                )}
              </>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Welcome;
