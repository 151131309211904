import React from "react";

const Footer = () => {
  return (
    <div className="foot-container">
      <div className="footer">
        <p>
          &copy; {new Date().getFullYear()} | One Node Prophet All rights
          reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
