import emailjs from "@emailjs/browser";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaGithub, FaLinkedin } from "react-icons/fa6";
import "../Styles/Contact.css";

const Contact = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".contact-case");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const email_service_id = process.env.REACT_APP_SERVICE_ID;
  const email_public_key = process.env.REACT_APP_PUBLIC_KEY;

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(email_service_id, "contact_form", form.current, {
        publicKey: email_public_key
      })
      .then(
        () => {
          setModalShow(true);

          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className={`contact-case`}>
      {" "}
      <div>
        <p
          className={`contact-me text-center  ${fadeIn ? "fade-in-down" : ""}`}
        >
          <span style={{ color: "orange" }}>Contact </span>
          <span style={{ color: "white" }}>ME</span>
        </p>
      </div>
      <div
        className={`contact-text ${fadeIn ? "fade-in-down" : ""}`}
        style={{ color: "white", fontSize: "20px" }}
      >
        <p>
          I would love to here from you! So please don't hesitate to reach out.
        </p>
      </div>
      <div className={`contact-main row ${fadeIn ? "fade-in" : ""}`}>
        <div className="col-md-12 col-lg-4">
          <div className="contact-info">
            <p>
              <span
                style={{
                  color: "orange",
                  fontWeight: "bolder",
                  fontSize: "22px"
                }}
              >
                Location:{" "}
              </span>
              <span style={{ color: "gray", fontSize: "19px" }}>
                Fort Worth, TX 76123
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "orange",
                  fontWeight: "bolder",
                  fontSize: "22px"
                }}
              >
                Phone:{" "}
              </span>
              <span style={{ color: "gray", fontSize: "19px" }}>
                +1 817-583-1168
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "orange",
                  fontWeight: "bolder",
                  fontSize: "22px"
                }}
              >
                Email:{" "}
              </span>
              <span>
                <a
                  style={{
                    color: "white",
                    fontSize: "19px",
                    textDecoration: "none"
                  }}
                  href="mailto:info@nodeprophet.com"
                >
                  carlito.pedidajr@gmail.com
                </a>
              </span>
            </p>
            <p>
              <span
                style={{
                  color: "orange",
                  fontWeight: "bolder",
                  fontSize: "22px"
                }}
              >
                Website:{" "}
              </span>
              <span>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "19px"
                  }}
                  href="https://www.nodeprophet.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.nodeprophet.com
                </a>
              </span>
            </p>
          </div>
          <div className="follow_box">
            <div
              className={`follow-container mt-5 ${fadeIn ? "fade-in-up" : ""}`}
            >
              <p>CONNECT WITH ME</p>
              <div className="d-flex justify-content-center">
                <a
                  href="https://www.linkedin.com/in/carlito-linked/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin
                    className="m-2"
                    size={30}
                    style={{ color: "orange" }}
                  />
                </a>
                <a
                  href="https://github.com/Carlito-Pedida"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub
                    className="m-2"
                    size={30}
                    style={{ color: "orange" }}
                  />
                </a>

                {/* <a
                href="https://twitter.com/1NodeProphet"
                target="_blank"
                rel="noreferrer"
              >
                <FaXTwitter
                  style={{ color: "orange" }}
                  className="m-2"
                  size={35}
                />
              </a>

              <a
                href="https://www.instagram.com/1nodeprophet/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  style={{ color: "orange" }}
                  className="m-2"
                  size={35}
                />
              </a>

              <a
                href="https://www.youtube.com/channel/UChK8Dig4lDOOSjv0bLbgPag"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareYoutube
                  className="m-2"
                  size={35}
                  style={{ color: "orange" }}
                />
              </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="formbox">
            <form ref={form} onSubmit={sendEmail}>
              <label
                htmlFor="from_name"
                className="mb-2 px-2"
                style={{ color: "white", textAlign: "left" }}
              >
                Name
              </label>
              <input
                id="from_name"
                className="mb-2"
                style={{ color: "white" }}
                type="text"
                name="from_name"
                required
                placeholder="Name"
              />
              <label
                htmlFor="from_email"
                className="mb-2 px-2"
                style={{ color: "white", textAlign: "left" }}
              >
                Email
              </label>
              <input
                id="from_email"
                className="mb-2"
                style={{ color: "white" }}
                type="email"
                name="from_email"
                required
                placeholder="Email"
              />
              <label
                htmlFor="message"
                className="px-2"
                style={{ color: "white", textAlign: "left" }}
              >
                Message
              </label>
              <textarea
                id="message"
                style={{ color: "white" }}
                type="text"
                name="message"
                required
                placeholder="Send me a quick Message"
                rows={5}
              />
              <button type="submit" className="button px-3">
                Send Message
              </button>
            </form>
          </div>
        </div>
        <div>
          <Modal centered show={modalShow} onHide={() => setModalShow(false)}>
            <Modal.Header
              style={{
                backgroundColor: "orange",
                color: "white"
              }}
              closeButton
            >
              <Modal.Title>Message Sent Successfully!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className="text-center">
                Thank you for your message! <br /> We will get back with you
                shortly.
              </h5>
            </Modal.Body>
            <Modal.Footer
              style={{
                backgroundColor: "orange",
                color: "white"
              }}
            >
              <Button variant="dark" onClick={() => setModalShow(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Contact;
