import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import devo from "../assets/worshipgrid/devo.png";
import featuredItem from "../assets/worshipgrid/featuredItem.png";
import featuredVids from "../assets/worshipgrid/featuredVids.png";
import home from "../assets/worshipgrid/home.png";
import merch from "../assets/worshipgrid/merch.png";
import messaging from "../assets/worshipgrid/messaging.png";
import setlist from "../assets/worshipgrid/setlist.png";
import sign_in from "../assets/worshipgrid/sign_in.png";
import sign_up from "../assets/worshipgrid/sign_up.png";
import tutorials from "../assets/worshipgrid/tutorials.png";
import userPage from "../assets/worshipgrid/userPage.png";

const Worshipgrid = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    {
      original: home,
      thumbnail: home
    },
    {
      original: featuredVids,
      thumbnail: featuredVids
    },
    {
      original: sign_up,
      thumbnail: sign_up
    },
    {
      original: sign_in,
      thumbnail: sign_in
    },
    {
      original: userPage,
      thumbnail: userPage
    },
    {
      original: devo,
      thumbnail: devo
    },
    {
      original: messaging,
      thumbnail: messaging
    },
    {
      original: tutorials,
      thumbnail: tutorials
    },
    {
      original: featuredItem,
      thumbnail: featuredItem
    },
    {
      original: merch,
      thumbnail: merch
    },
    {
      original: setlist,
      thumbnail: setlist
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
        <div className="mb-3">
          <Button
            onClick={() => {
              window.open("https://worship-grid-ui.vercel.app/", "_blank");
            }}
            variant="warning"
          >
            VIEW LIVE SITE
          </Button>
        </div>

        <div className="featured col-md-12 col-lg-6">
          <div className="changing px-2 mb-3">
            <h4>
              Worship Grid:{" "}
              <span style={{ color: "orange" }}> Full Stack </span>
              Web Application
            </h4>
            <p>
              React, Node, Express, Javascript, Typescript, SQL, ECommerce, AWS
              RDS <br /> JWT, API, Redux, HTML, CSS, Chakra UI, Bootstrap,
              Stripe
              <br />
              Role: Full Stack Designer and Developer
            </p>
          </div>
          <p>Home Page</p>
          <img
            style={{ cursor: "pointer" }}
            onClick={setShow}
            src={home}
            alt="Portfolio"
          />
        </div>

        <div className="col-md-12 col-lg-4">
          <div className="row">
            <div className="product">
              <p>User Profile</p>
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={userPage}
                alt="Portfolio"
              />
            </div>
          </div>
          <div className="secondary row">
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product my-2">
                <p>Tutorials Sections</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={tutorials}
                  alt="Portfolio"
                />
              </div>
              <div className="product my-2">
                <p>Online Store</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={merch}
                  alt="Portfolio"
                />
              </div>
            </div>
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product col my-2">
                <p>Messaging Area</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={messaging}
                  alt="Portfolio"
                />
              </div>
              <div className="product col my-2">
                <p>Featured Item</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={featuredItem}
                  alt="Portfolio"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Button
            onClick={() => {
              window.open("https://worship-grid-ui.vercel.app/", "_blank");
              setShow(false);
            }}
          >
            VIEW LIVE SITE
          </Button>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Worshipgrid;
