import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import exohome from "../assets/exocars/exohome.png";
import exovehicles from "../assets/exocars/exovehicles.png";
import exovehicles2 from "../assets/exocars/exovehicles2.png";
import exocrud from "../assets/exocars/exocrud.png";
import exocardetail from "../assets/exocars/exocardetail.png";
import exoabout from "../assets/exocars/exoabout.png";

const Carportal = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    {
      original: exohome,
      thumbnail: exohome
    },
    {
      original: exovehicles,
      thumbnail: exovehicles
    },
    {
      original: exovehicles2,
      thumbnail: exovehicles2
    },
    {
      original: exocardetail,
      thumbnail: exocardetail
    },
    {
      original: exocrud,
      thumbnail: exocrud
    },
    {
      original: exoabout,
      thumbnail: exoabout
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
        <div className="repo_link">
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://github.com/Carlito-Pedida/react-frontend-framework"
            target="_blank"
            rel="noreferrer"
          >
            <p>project_files_car_portal</p>
          </a>
        </div>
        <div className="featured col-md-12 col-lg-6 my-3">
          <div className="changing px-2 mb-3">
            <h4>
              Vehicle Inventory:{" "}
              <span style={{ color: "orange" }}> Front-End </span> with REACT
            </h4>
            <p>
              React, Javascript, API
              <br />
              Role: UI Designer and Developer
            </p>
          </div>
          <p>Featured Vehicles Page</p>

          <img
            style={{ cursor: "pointer" }}
            onClick={setShow}
            src={exohome}
            alt="Portfolio"
          />
        </div>

        <div className="col-md-12 col-lg-4">
          <div className="row">
            <div className="product">
              <p>About Info</p>
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={exoabout}
                alt="Portfolio"
              />
            </div>
          </div>
          <div className="secondary row">
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product my-2">
                <p>Inventory 1</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={exovehicles}
                  alt="Portfolio"
                />
              </div>
              <div className="product my-2">
                <p>Inventory 2</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={exovehicles2}
                  alt="Portfolio"
                />
              </div>
            </div>
            <div className="secondary col-md-12 col-lg-6 lg-row">
              <div className="product col my-2">
                <p>CRUD Operation</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={exocrud}
                  alt="Portfolio"
                />
              </div>
              <div className="product col my-2">
                <p>Vehicle Detail</p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={setShow}
                  src={exocardetail}
                  alt="Portfolio"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>Vehicle Inventory: Gallery</Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Carportal;
