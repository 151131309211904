import React from "react";
import Landing from "./components/Landing";
import About from "./components/About";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
      <div style={{ textAlign: "center" }}>
        <Navigation />
        <div id="home">
          <Landing />
        </div>
        <div id="portfolio">
          <Projects />
        </div>
        <div id="services">
          <Skills />
        </div>
        <div id="contact">
          <Contact />
        </div>
        <div id="about">
          <About />
        </div>
        <Footer />
      </div>
    </ChakraProvider>
  );
}

export default App;
