import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import social from "../assets/ionic/social.png";
import posts from "../assets/ionic/posts.png";
import folio1 from "../assets/ionic/folio1.png";
import folio2 from "../assets/ionic/folio2.png";
import friendlist from "../assets/ionic/friendlist.png";
import gallery from "../assets/ionic/gallery.png";
import pizzastore from "../assets/ionic/pizzastore.png";
import pizzafaves from "../assets/ionic/pizzafaves.png";
import pizzaamenu from "../assets/ionic/pizzaamenu.png";
import pizzacart from "../assets/ionic/pizzacart.png";
import pizzacart1 from "../assets/ionic/pizzacart1.png";
import taskadd from "../assets/ionic/taskadd.png";
import taskdelete from "../assets/ionic/taskdelete.png";
import orderselect from "../assets/ionic/orderselect.png";

const Mobileapp = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const images = [
    { original: social },
    { original: posts },
    { original: friendlist },
    { original: gallery },
    { original: pizzastore },
    { original: pizzafaves },
    { original: pizzaamenu },
    { original: pizzacart },
    { original: pizzacart1 }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(".showcase-main");
      const sectionPosition = section.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      // If the section is in view
      if (sectionPosition < screenHeight * 0.75) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={`product-main row ${fadeIn ? "fade-in" : ""}`}>
        <div>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://github.com/stars/Carlito-Pedida/lists/react-native"
            target="_blank"
            rel="noreferrer"
          >
            <p>project_files_react_native</p>
          </a>
        </div>
        <div className="featured col-12 mb-3">
          <div className="changing my-4 px-2">
            <h4>
              Full Stack<span style={{ color: "orange" }}> Mobile App </span>
              Development
            </h4>
            <p>
              React Native, Ionic, Capacitor, Node, Express, SQL
              <br />
              Role: UI Designer | Full Stack Developer
            </p>
          </div>
          <div className="my-5 row">
            <h3 className="mb-3">Portfolio App</h3>
            <div className="col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={social}
                alt="Portfolio"
              />
            </div>
            <div className="col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={posts}
                alt="Portfolio"
              />
            </div>
            <div className="col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={friendlist}
                alt="Portfolio"
              />
            </div>
            <div className="col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={gallery}
                alt="Portfolio"
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="secondary row">
            <h3 className="mb-3">Pizza Store App</h3>

            <div className="product col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={pizzastore}
                alt="Portfolio"
              />
            </div>
            <div className="product col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={pizzafaves}
                alt="Portfolio"
              />
            </div>
            <div className="product col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={pizzaamenu}
                alt="Portfolio"
              />
            </div>
            <div className="product col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={orderselect}
                alt="Portfolio"
              />
            </div>
            <div className="product col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={pizzacart1}
                alt="Portfolio"
              />
            </div>
            <div className="product col-lg-3 my-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={setShow}
                src={pizzacart}
                alt="Portfolio"
              />
            </div>
          </div>

          <div className="my-5 row">
            <div className="col-lg-6">
              <h3 className="mb-3">Portfolio App</h3>

              <div className="row">
                <div className="product col-lg-6 my-3">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={setShow}
                    src={folio1}
                    alt="Portfolio"
                  />
                </div>
                <div className="product col-lg-6 my-3">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={setShow}
                    src={folio2}
                    alt="Portfolio"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h3 className="mb-3">(Full-Stack) To Do App</h3>

              <div className="row">
                <div className="product col-lg-6 my-3">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={setShow}
                    src={taskadd}
                    alt="Portfolio"
                  />
                </div>
                <div className="product col-lg-6 my-3">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={setShow}
                    src={taskdelete}
                    alt="Portfolio"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="md" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>Mobile Apps: Gallery</Modal.Header>
        <Modal.Body>
          {" "}
          <ImageGallery items={images} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Mobileapp;
