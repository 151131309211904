import React from "react";
import "../Styles/Landing.css";
import Welcome from "../props/Welcome";

const Landing = () => {
  return (
    <div className="landing">
      <Welcome
        spiel={`"Life is a progressive pursuit of
        discipline, adaptability, and service-centered leadership skills –
        characteristics I am always excited about bringing to any
        entity that requires steadfast work ethic and a caring sense of service."`}
        greeting1="Hello there! "
        profileName="I am CARLITO PEDIDA"
        greeting2="Welcome to my Website!"
        contactButton1="View Projects"
        contactButton2="Contact me"
      />
    </div>
  );
};

export default Landing;
