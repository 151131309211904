import React from "react";
import { Navbar, Nav, Container, Offcanvas } from "react-bootstrap";

const Navigation = () => {
  return (
    <div>
      {[false, "lg"].map((expand, index) => (
        <Navbar
          collapseOnSelect
          key={index}
          expand={expand}
          className="px-3 navbar bg-body-primary"
          fixed="top"
          style={{ height: "75px", opacity: "90%" }}
          bg="black"
          data-bs-theme="dark"
        >
          <Container>
            <Navbar.Brand style={{ color: "GrayText" }} href="#home">
              <div>
                <h3>
                  <strong>
                    <span
                      style={{
                        color: "white",
                        fontFamily: "Acme, sans-serif",
                        fontStyle: "italic",
                        fontSize: "35px"
                      }}
                    >
                      Pedida.
                    </span>
                    <span
                      style={{
                        color: "orange",
                        fontFamily: "Acme, sans-serif",
                        fontSize: "37px"
                      }}
                    >
                      Portfolio
                    </span>
                  </strong>
                </h3>
              </div>
            </Navbar.Brand>
            <div>
              <Navbar.Toggle
                fix="top"
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Select Page
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="#home">HOME</Nav.Link>
                    <Nav.Link href="#portfolio">PROJECTS</Nav.Link>
                    <Nav.Link href="#services">TECHNOLOGY</Nav.Link>
                    {/* <Nav.Link href="#resume">CREDENTIALS</Nav.Link> */}
                    <Nav.Link href="#contact">CONTACT</Nav.Link>
                    <Nav.Link href="#about">ABOUT</Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          </Container>
        </Navbar>
      ))}
    </div>
  );
};

export default Navigation;
